import * as React from "react";
import gif1 from "./assets/main.gif";
// import topbanner from './assets/Banner1920x300.001.png'
import "./assets/styles.css";
import NavBar from "./navbar";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

// styles
const imgStyle = {
  color: "#232129",
  padding: 10,
  width: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const footerStyle = {
  textAlign: "center",
  color: "#716969",
  fontSize: 10,
};

const pageStyles = {
  color: "#232129",
  backgroundColor: "#ffffff",
  padding: 0,
  // height: "100%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <script src={withPrefix("sumo.js")} type="text/javascript" />
      </Helmet>

      <title>Cogni-Trax</title>
      <NavBar />

      {/* <div className="content"  >
            <img src={logo}  />
      </div> */}

      <div className="content">
        {/* <img src={topbanner}  style={imgStyle} alt="cogni-trax technology"/> */}
        <img src={gif1} style={imgStyle} alt="cogni-trax technology" />
      </div>
      {/* <video width="100%" height="100%"  controls autoPlay muted loop >
        <source src={video1} type="video/mp4" />
      </video> */}

      {/* <video width="100%" height="100%"  controls autoPlay muted loop >
        <source src={vid1} type="video/mp4" />
      </video>

      <video width="100%" height="100%"  autoPlay muted loop >
        <source src={vid2} type="video/mp4" />
      </video> */}

      <div id="footer" style={footerStyle}>
        <h3> ceo@cogni-trax.com </h3>
        <p className="copyright">&copy; Cogni-Trax </p>
      </div>
    </main>
  );
};

export default IndexPage;
